<template>
  <div class="home_inner">
    <div class="banner" v-show="isVShow">
      <h2>Update Your 2024 Car Quote</h2>
      <div class="cart_swapper">
        <h3 class="title">See Available Plans in {{ MyAdd }}</h3>
        <div class="content">
          <h3>How Many Vehicles Need Coverage?</h3>
          <div class="row">
            <div
              @click="onCars(1)"
              :class="form.cars == 1 ? 'col col_1 active' : 'col col_1'"
            >
              <span class="num">1</span>
            </div>
            <div
              @click="onCars(2)"
              :class="form.cars == 2 ? 'col col_2 active' : 'col col_2'"
            >
              <span class="num">2</span>
            </div>
            <div
              @click="onCars(3)"
              :class="form.cars == 3 ? 'col col_3 active' : 'col col_3'"
            >
              <span class="num">3</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-progress
      :percentage="percentage"
      :stroke-width="10"
      :show-text="false"
      define-back-color="rgba(222, 222, 222, 1)"
      color="rgba(82, 178, 222, 1)"
    ></el-progress>
    <el-main>
      <section class="step">
        <div class="lf img_swapper">
          <img alt="illustration" src="@/assets/imgs/illustration1.png" />
        </div>
        <div class="rg mb">
          <h4>Compare in Minutes</h4>
          <p>Tap a button above to begin.</p>
          <p>
            Why spend hours trying to figure out which auto insurance providers
            offer the most affordable rates and best policy for your needs?
            filling out a quick and simple form with some basic information
            about yourself and your vehicle.
          </p>
        </div>
      </section>
      <section class="step bgcolor">
        <div class="lf is_show mb">
          <h4>Get Competitive Rates</h4>
          <p>Compare quotes and SAVE with our easy-to-use tool.</p>
          <p>
            The car insurance providers in the network are competing for your
            business,Our Smart matching engine will sift the Motor insurance
            policies and only present you with relevant and valid options.
          </p>
        </div>
        <div class="rg img_swapper is_show">
          <img alt="illustration" src="@/assets/imgs/illustration2.png" />
        </div>
        <div class="lf img_swapper is_phone_show">
          <img alt="illustration" src="@/assets/imgs/illustration2.png" />
        </div>
        <div class="rg is_phone_show pb">
          <h4>Get Competitive Rates</h4>
          <p>Compare quotes and SAVE with our easy-to-use tool.</p>
          <p>
            The car insurance providers in the network are competing for your
            business,Our Smart matching engine will sift the Motor insurance
            policies and only present you with relevant and valid options.
          </p>
        </div>
      </section>
      <section class="step">
        <div class="lf img_swapper">
          <img alt="illustration" src="@/assets/imgs/illustration3.png" />
        </div>
        <div class="rg mb">
          <h4>A Lot Of Savings</h4>
          <p>
            Let us make it simple for you to save!You have the freedom to choose
            the best policy ption that’s right for you.
          </p>
          <p>100% FREE Initial Consultation.</p>
        </div>
      </section>
      <div class="recommend">
        <p class="r_content">
          We shop highly rated insurance companies to save you time & money.
        </p>
        <div class="zanzhu">
          <img alt="wellcare" src="@/assets/imgs/geico.png" />
          <img alt="Aetna" src="@/assets/imgs/century.png" />
          <img alt="blue" src="@/assets/imgs/progressive.png" />
          <img alt="cigama" src="@/assets/imgs/allstate.png" />
          <img alt="CVS_Health_logo" src="@/assets/imgs/libcrty-mutual.png" />
          <img alt="h" src="@/assets/imgs/nationwide.png" />
          <img alt="kalser" src="@/assets/imgs/travelers.png" />
          <p class="clearflex"></p>
        </div>
      </div>
      <div class="trademark">
        <img alt="trademark_1" src="@/assets/imgs/trademark_1.png" />
        <img alt="trademark_2" src="@/assets/imgs/trademark_2.png" />
        <img alt="trademark_3" src="@/assets/imgs/trademark_3.png" />
      </div>
    </el-main>
  </div>
</template>

<script>
import { GetIP, GetIPadd, getIpAddress } from "@/api/index";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      MyAdd: "",
      percentage: 10,
      form: {
        cars: 0,
      },
      iscars: false,
      isVShow: false,
      tid: "",
    };
  },
  created() {
    this.$nextTick(() => {
      this.isVShow = true;
      let cardfrom = localStorage.getItem("cardfrom");
      if (cardfrom) {
        let temp = { ...JSON.parse(cardfrom), ...this.form };
        this.form = temp;
      }
    });
  },
  mounted() {
    this.isfrom();
    this.getIP();
    this.getSrc();

    // const fullPath = this.$route.fullPath; //获取path
    // const utm_sorce = fullPath.split("&")[0]; //拿到前缀
    // // 定义渠道名与对应SRC的映射关系
    // const channelMappings = {
    //   eric: "Auto-Eric",
    //   Li: "Auto-Li",
    //   "032701": "Xiaoming",
    //   default: "BSM-Internalfacebook",
    // };
    // //判断前缀是FB还是SamsonAds
    // switch (utm_sorce.split("=")[0]) {
    //   //投放为FB
    //   case "/?utm_sorce":
    //     const sourceName = utm_sorce.split("=")[1]; // 拿到渠道名
    //     // 判断渠道名并设置对应的SRC
    //     localStorage.setItem(
    //       "source",
    //       channelMappings[sourceName] || channelMappings.default
    //     );
    //     const utm_tid = fullPath.split("&")[1]; //拿到tid
    //     this.tid = utm_tid.split("=")[1];
    //     localStorage.setItem("tid", this.tid);
    //     break;
    //   //投放为SamsonAds
    //   case "/?tid":
    //     localStorage.setItem("source", "BSM-Internalfacebook");
    //     this.tid = utm_sorce.split("=")[1];
    //     localStorage.setItem("tid", this.tid);
    //     break;
    //   //前缀不是FB和SamsonAds，SRC为BSM-Internalfacebook
    //   default:
    //     localStorage.setItem("source", "BSM-Internalfacebook");
    //     break;
    // }
  },
  methods: {
    onCars(num) {
      this.form["cars"] = num;
      this.next();
    },
    next() {
      localStorage.setItem("cardfrom", JSON.stringify(this.form));
      this.$router
        .push({
          path: "/ProcessForm",
          params: {
            cars: this.form["cars"],
            Myadd: this.MyAdd,
          },
        })
        .catch(() => {});
    },
    isfrom() {
      if (localStorage.getItem("percentage")) {
        this.percentage = localStorage.getItem("percentage") - 0;
      }
    },
    async getIP() {
      if (!localStorage.getItem("userip")) {
        // const res = await GetIP();
        const res = await getIpAddress();
        console.log(res);

        this.getipadd(res);
      } else {
        this.MyAdd = JSON.parse(localStorage.getItem("userip")).city;
      }
    },
    async getipadd(ip) {
      console.log(ip);
      const res = await GetIPadd(ip)
        .then(() => {
          this.MyAdd = res.city;
          localStorage.setItem("userip", JSON.stringify(res));
        })
        .catch(() => {
          localStorage.setItem("userip", JSON.stringify({ ip }));
        });
    },
    getSrc() {
      const query = this.$route.query; //获取query
      const utm_sorce = query.utm_sorce; //拿到前缀
      // 定义渠道名与对应SRC的映射关系
      const channelMappings = {
        eric: "Auto-Eric",
        Li: "Auto-Li",
        caipei: "caipei",
        Liu: "Liu",
        "032701": "Xiaoming",
        default: "BSM-selecthomequote",
      };
      //判断前缀是FB还是SamsonAds
      if (utm_sorce) {
        // 判断渠道名并设置对应的SRC
        localStorage.setItem(
          "source",
          channelMappings[utm_sorce] || channelMappings.default
        );
        localStorage.setItem("tid", query.tid);
      } else {
        localStorage.setItem("source", "BSM-selecthomequote");
        localStorage.setItem("tid", query.tid);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/layout.scss";

.home_inner {
  margin-top: 70px;
  overflow-x: hidden;

  .banner {
    background-image: url(../../public/imgs/banner1.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 20%);
    height: 500px;
    padding: 50px 10%;

    h2 {
      line-height: 1.5;
      font-size: 35px;
      color: rgba(255, 255, 255, 1);
      width: 650px;
      text-align: center;
    }

    .cart_swapper {
      background-image: url(../../public/imgs/cartbg.webp);
      background-repeat: no-repeat;
      background-size: cover;
      height: 400px;
      width: 650px;
      border-radius: 10px;
      margin-top: 20px;

      h3.title {
        font-size: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        line-height: 3;
      }

      .content {
        width: 100%;
        margin-top: 65px;

        h3 {
          text-align: center;
          line-height: 2;
          font-size: 28px;
          color: rgba(28, 56, 136, 1);
        }

        text-align: center;

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;

          .col {
            position: relative;
            padding-top: 25px;
            cursor: pointer;
            border-radius: 20px;
            border: solid 1px #74c7ee;
            height: 90px;

            .num {
              position: absolute;
              top: 10px;
              font-size: 18px;
              display: block;
              text-align: center;
              width: 100%;
              color: #74c7ee;
            }
          }

          .col_1 {
            width: 18%;
            background-image: url(../../public/imgs/car_1_1.webp);
            background-repeat: no-repeat;
            background-position: 50% 70%;
          }

          .col_2 {
            width: 22%;
            margin: 0px 5%;
            background-image: url(../../public/imgs/car_2_2.webp);
            background-repeat: no-repeat;
            background-position: 50% 70%;
          }

          .col_3 {
            width: 25%;
            background-image: url(../../public/imgs/car_3_3.webp);
            background-repeat: no-repeat;
            background-position: 50% 70%;
          }

          .col_1:hover,
          .col_1.active {
            background-color: #74c7ee;
            background-image: url(../../public/imgs/car_active_1.webp);

            .num {
              color: #fff;
            }
          }

          .col_2:hover,
          .col_2.active {
            background-color: #74c7ee;
            background-image: url(../../public/imgs/car_active_2.webp);

            .num {
              color: #fff;
            }
          }

          .col_3:hover,
          .col_3.active {
            background-color: #74c7ee;
            background-image: url(../../public/imgs/car_active_3.webp);

            .num {
              color: #fff;
            }
          }
        }
      }

      .next_btn {
        background: rgba(255, 150, 150, 1);
        color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        width: 128px;
        height: 46px;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .error_txt {
      color: #eb737a;
      margin: 0px 0px 0px 10px;
      padding: 0px;
      height: 25px;
      line-height: 25px;
      text-align: left;
      margin-left: 19%;
    }

    .el-progress-bar__outer {
      border-radius: 0px !important;
    }
  }

  .el-main {
    padding: 0px;
    overflow: hidden;

    .step {
      display: flex;
      min-height: 250px;
      box-sizing: border-box;
      padding: 0px 25%;
      justify-content: center;
      align-items: center;

      .lf,
      .rg {
        width: 80%;
        margin-bottom: 10px;

        img {
          vertical-align: middle;
          height: 200px;
          margin: 30px 0px;
        }

        padding: 0px 5%;

        h4 {
          font-size: 25px;
          line-height: 3;
          color: rgba(82, 178, 222, 1);
          text-align: center;
        }

        p {
          font-size: 18px;
          color: rgba(28, 56, 136, 1);
          text-align: left;
        }
      }

      .is_show {
        display: block;
      }

      .is_phone_show {
        display: none;
      }

      .img_swapper {
        width: 20%;
        text-align: center;
      }
    }

    .bgcolor {
      background: rgba(246, 249, 254, 1);
    }

    .recommend {
      text-align: center;

      .r_content {
        font-size: 16px;
        color: rgba(28, 56, 136, 1);
        font-weight: 700;
        padding: 10px 0px;
      }

      .zanzhu {
        width: 100%;
        background: rgba(246, 249, 254, 1);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        text-align: center;

        img {
          vertical-align: middle;
          margin: 20px 30px;
          width: 110px;
        }
      }

      .clearflex {
        width: 100%;
        clear: both;
      }
    }

    .trademark {
      text-align: center;
      margin: 30px 0px;

      img {
        padding: 50px;
      }
    }
  }

  .el-progress-bar__outer {
    border-radius: 0px;

    .el-progress-bar__inner {
      border-radius: 0px 100px 100px 0px;
    }
  }
}

@media screen and (max-width: 992px) {
  .home_inner {
    .banner {
      background-size: 100% 100%;
      height: 480px;
      text-align: center;

      h2 {
        width: 100%;
        padding: 10px 0px;
        font-size: 35px;
      }

      .cart_swapper {
        width: 100%;
        margin-top: 10px;
        height: 380px;

        h3.title {
          line-height: 3;
        }

        .content {
          margin-top: 65px;

          h3 {
            font-size: 24px;
            line-height: 2;
          }
        }
      }

      .error_txt {
        margin-left: 12%;
        font-size: 14px;
      }
    }

    .el-main {
      .step {
        padding: 0px 5%;

        .lf,
        .rg {
          p {
            font-size: 20px;
          }
        }

        .mb {
          margin-bottom: 40px;
        }

        .pb {
          padding-bottom: 40px;
        }

        .is_show {
          display: block;
        }

        .is_phone_show {
          display: none;
        }
      }

      .recommend {
        .r_content {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home_inner {
    margin-top: 76px;

    .banner {
      width: 100%;
      padding: 0;
      background-size: 100% 100%;
      height: 450px;

      h2 {
        padding: 10px;
        font-size: 25px;
      }

      .cart_swapper {
        width: 86%;
        margin: 0px auto;

        h3.title {
          line-height: 3;
        }

        .content {
          margin-top: 65px;

          h3 {
            font-size: 24px;
            line-height: 1.5;
          }
        }
      }

      .error_txt {
        margin-left: 12%;
      }
    }

    .el-main {
      .step {
        padding: 0px;
        display: block;

        .lf,
        .rg {
          width: calc(100% - 10%);
          text-align: center;
          box-sizing: content-box;

          img {
            margin-bottom: 10px;
            height: 120px;
          }

          h4 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }

        .mb {
          margin-bottom: 40px;
        }

        .pb {
          padding-bottom: 40px;
        }

        .is_show {
          display: none;
        }

        .is_phone_show {
          display: block;
        }

        .img_swapper {
          text-align: center;
        }
      }

      .trademark {
        img {
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 690px) {
  .home_inner {
    .banner {
      .cart_swapper {
        .content {
          img {
            border-radius: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .home_inner {
    .banner {
      h2 {
        font-size: 20px;
      }

      .cart_swapper {
        width: 96%;
        margin: 0px auto;

        h3.title {
          line-height: 3;
        }

        .content {
          margin-top: 50px;

          h3 {
            font-size: 24px;
            line-height: 1.5;
          }

          .row {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .col {
              position: relative;
              padding-top: 25px;
              cursor: pointer;
              border-radius: 20px;
              border: solid 1px #74c7ee;
              height: 90px;

              .num {
                position: absolute;
                top: 10px;
                font-size: 18px;
                display: block;
                text-align: center;
                width: 100%;
                color: #74c7ee;
              }
            }

            .col_3 {
              width: 28%;
            }
          }
        }
      }
    }
  }
}
</style>
